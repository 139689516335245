.large-banner {    
    background: url("/courses/assets/img/banner_bg.png");
    height: 170px;    
    background-size: cover;    
    .cta{
      margin : auto 0;
    }    
    @media (max-width:475px) {
      padding-top: 0;
      padding-bottom : 0;
      height: 160px;
    }
    a{
      color: white;
    }
    
  }
