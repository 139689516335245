@import "./src/sass/variables";

.standard-button {
  img{
    height: 16px;
    width: 16px;
  }
  &.btn {
    padding-top: 0.75rem;
    padding-bottom: 0.75rem;
    font-size: 1rem;
    line-height: 1;

    &.btn-sm {
      padding-top: 0.5rem;
      padding-bottom: 0.5rem;
    }
  }
  &.border-less {
    border: none;
  }
  &.btn-primary {
    color: #fff;
    background-color: $giraffe-primary;
    border-color: $giraffe-primary;
    font-weight: 700;
  }
  &.btn-secondary {
    color: #fff;
    background-color: $giraffe-secondary;
    border-color: $giraffe-secondary;
    font-weight: 700;
  }
  &.btn-disabled {
    background-color: $giraffe-background;
    border-color: $giraffe-background;
    font-weight: 700;
    cursor: default !important;
  }

  &.btn-success {
    color: #fff;
    background-color: $giraffe-success;
    border-color: $giraffe-success;
  }

  &.btn-outline-primary {
    color: $giraffe-primary;
    border-color: $giraffe-primary;
  }

  &.btn-outline-danger {
    color: $giraffe-danger;
    border-color: $giraffe-light
  }

  &.btn-outline-dark {
    color: $giraffe-dark;
    border-color: $giraffe-dark;
  }

  &.btn-outline-light {
    color: $giraffe-primary;
    border-color: $giraffe-light;
  }

  &.btn-outline-secondary {
    color: $giraffe-secondary;
    border-color: $giraffe-secondary;
  }

  &.btn-outline-success {
    color: $giraffe-success;
    border-color: $giraffe-success;
  }

  &:not(:disabled):not(.disabled),
  :not(:disabled):not(.disabled) {
    &.btn-success {
      &:hover {
        color: $giraffe-success;
        background-color: rgba($giraffe-success, 0.1);
        border-color: $giraffe-success;
      }
      &:active {
        color: #fff;
        background-color: $giraffe-success;
        border-color: $giraffe-success;
      }

      &:focus {
        box-shadow: 0 0 0 0.15rem rgba($giraffe-success, 0.3);
      }
    }

    &.btn-outline-primary {
      &:hover {
        background-color: rgba($giraffe-primary, 0.1);
        border-color: $giraffe-primary;
      }

      &:active {
        color: $giraffe-primary;
        background-color: #fff;
        border-color: $giraffe-primary;
      }
      &:focus {
        box-shadow: 0 0 0 0.15rem rgba($giraffe-primary, 0.3);
      }
    }

    &.btn-outline-danger {
      &:hover {
        background-color: rgba($giraffe-danger, 0.1);
        border-color: $giraffe-danger;
      }

      &:active {
        color: $giraffe-danger;
        background-color: #fff;
        border-color: $giraffe-danger;
      }
      &:focus {
        box-shadow: 0 0 0 0.15rem rgba($giraffe-danger, 0.3);
      }
    }

    &.btn-outline-dark {
      &:hover {
        background-color: rgba($giraffe-dark, 0.1);
        border-color: $giraffe-dark;
      }

      &:active {
        color: $giraffe-dark;
        background-color: #fff;
        border-color: $giraffe-dark;
      }
      &:focus {
        box-shadow: 0 0 0 0.15rem rgba($giraffe-dark, 0.3);
      }
    }

    &.btn-outline-light {
      &:hover {
        background-color: rgba($giraffe-light, 0.1);
        border-color: $giraffe-light;
      }

      &:active {
        color: $giraffe-light;
        background-color: #fff;
        border-color: $giraffe-light;
      }
      &:focus {
        box-shadow: 0 0 0 0.15rem rgba($giraffe-light, 0.3);
      }
    }

    &.btn-outline-secondary {
      &:hover {
        background-color: rgba($giraffe-secondary, 0.1);
        border-color: $giraffe-secondary;
      }

      &:active {
        color: $giraffe-secondary;
        background-color: #fff;
        border-color: $giraffe-secondary;
      }
      &:focus {
        box-shadow: 0 0 0 0.15rem rgba($giraffe-secondary, 0.3);
      }
    }

    &.btn-outline-success {
      &:hover {
        background-color: rgba($giraffe-success, 0.1);
        border-color: $giraffe-success;
      }

      &:active {
        color: $giraffe-success;
        background-color: #fff;
        border-color: $giraffe-success;
      }
      &:focus {
        box-shadow: 0 0 0 0.15rem rgba($giraffe-success, 0.3);
      }
    }

    &.btn-light {
      color:$giraffe-danger;
      &:hover {
        background-color: rgba(#fff, 0.1);
        border-color: $giraffe-success;
      }

      &:active {
        
        background-color: #fff;
        border-color: $giraffe-success;
      }
      &:focus {
        box-shadow: 0 0 0 0.15rem rgba($giraffe-success, 0.3);
      }
    }
  }
}
