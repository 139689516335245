$font-weight-medium: 500;
$primary-color: #ffa400;

$giraffe-success: #00a860;
$giraffe-primary: #1b00b0;
$giraffe-danger: #f23e3e;
$giraffe-dark: #2b2a2a;
$giraffe-light: #b5cded;
$giraffe-secondary: #f7941e;
$giraffe-background : #F2F2F2;

