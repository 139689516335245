@import "./custom-bootstrap";
@import "./variables";
//all styles that override the boostrap styles
$navbar-height: 4rem;
body{
  background-color: $giraffe-background;
  height: 100%;
  .giraffe-container{    
    min-height: 100vh;    
    
  }
  .giraffe-container-h{    
    min-height: 100vh;    
  }
}
.bread-crumbs + .page-title{
  margin-top: 1rem !important;
}

.page-title{
  margin-top: 2rem;
}

h1 {
  font-weight: $font-weight-bold;
}

.font-weight-medium {
  font-weight: $font-weight-medium;
}

.no-gutters {
  margin-right: 0;
  margin-left: 0;

  > .col,
  > [class*="col-"] {
    padding-right: 0;
    padding-left: 0;
  }
}

.content-icon{
  height: 37px;
}

.edit-icon{
  height: 20px;
  &.large{
    height: 18px !important;
    width: 18px !important;
  }
}

.footer-content {
  color: #757575;
}

.success {
  color: $giraffe-success;
}
.danger {
  color: $giraffe-danger;
}
.in-progress{
  color : #fea128;
}

.cta-button{
  min-width: 150px;
  @media (max-width:380px) {
      min-width: 125px;
  }
}


.Toastify__toast--success{
  background: #67ce4f !important;
}

.Toastify__toast--error{
  background: #ed4849 !important;
}

.text-pre-wrap{
  white-space: pre-wrap;
}


.text-size-1{
  font-size: 2rem!important; 
  font-weight: 1000; 
}

.text-size-2{
  font-size: 1.5rem!important; 
  font-weight: 800; 
  line-height: 1.2;
}
.text-size-3{
  font-size: 1.25rem!important; 
  font-weight: 800;
}
.text-size-4{
  font-size: 1rem!important; 
}
.text-size-5{
  font-size: 0.8rem!important; 
}
.text-bold{
  font-weight: 1000; 
}

.text-orange{
  color: #f7941e;
}
.text-blue{
  color: #1b00b0 !important;
}

@media (max-width:475px) {
  
  .text-size-1{
    font-size: 2rem!important; 
    font-weight: 1000; 
  }
  
  .text-size-2{
    font-size: 1.5rem!important; 
    font-weight: 800; 
  }
  .text-size-3{
    font-size: 1.25rem!important; 
    font-weight: 800;
  }
  .text-size-4{
    font-size: 1rem!important; 
  }
  .text-size-5{
    font-size: 0.8rem!important; 
  }
}

.category-bg{
  background-image: url("/courses/assets/img/category_bg.png"); 
  background-repeat: no-repeat;
  background-size: contain;  
}