@import "../variables";
.browse-courses {
  hr {
    margin-bottom: 0;
  }
  .filter {
    border-radius: 5px;
    box-shadow: 0 0 10px 0 rgba(51, 56, 70, 0.17);
    border: solid 1px #e0e0e0;
    background-color: #ffffff;
    margin-top: -2rem;
    p {
      margin-top: 12px;
    }
  }
  .danger-text {
    color: #f92d2d;
  }

  .browse-course-detail {
    border-radius: 5px;
    border: solid 1px #d3d3d3;
    padding-bottom: 1rem;
    .description {
      color: #808080;
      @media (min-width: 768px) {
        height: 7.5rem;
        text-overflow: ellipsis;
        overflow: hidden;
        -webkit-line-clamp: 5;
        display: -webkit-box;
        -webkit-box-orient: vertical;
        overflow: hidden;
        text-overflow: ellipsis;
      }
    }
    .course-image {
      height: 200px;
      color: #fff;
      background-size: cover;
      background-repeat: no-repeat;
      .category {
        background-color: #06bf7c;
        padding-top: 0.5rem;
        padding-bottom: 0.5rem;
        @media (max-width: 475px) {
          padding-top: 0.24rem;
          padding-bottom: 0.4rem;
        }
      }
      @media (max-width: 767px) {
        height: 175px;
      }
    }
    a {
      color: unset;
    }

    .chapter {
      background-color: #f8f8f8;
      padding: 0.75rem;
      font-size: 0.9rem;
      @media (max-width: 475px) {
        padding: 0.5rem;
      }
      .chapter-number {
        min-width: 30px;
        margin: 0 0.75rem 0 0;
        padding-top: 5px;
        width: 30px;
        height: 30px;
        background-color: #fff;
        color: #1b00b0;
        font-weight: 1000;
        border-radius: 50%;
        border: solid 1px #d3d3d3;
        text-align: center;
        vertical-align: middle;
        @media (min-width: 476px) {
          padding-top: 3px;
        }
      }
    }
  }

  .content-img {
    height: 14px;
    @media (max-width: 475px) {
      height: 12px;
    }
  }

  .stats {
    .text-size-5 {
      font-size: 14px;
      @media (max-width: 475px) {
        font-size: 12px;
      }
    }
  }
  .share-img {
    height: 1.25rem;
  }
  .view-more-less-btn {
    color: $giraffe-primary;
    border: 1px solid $giraffe-primary;
    background: #fff;
    font-size: 16px;
    border-radius: 5px;
    width: 80px;
    padding-left: 0.3rem !important;
    padding-right: 0.3rem !important;
    padding-top: 0.5rem !important;
    padding-bottom: 0.5rem !important;
    font-weight: 500;
  }
}
